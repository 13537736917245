import React, { useState } from "react";

import { ShareButton } from "components/icons";
import { Wrapper } from "./Share.styles";

const Share = (props) => {
  //PROPS
  const { uri } = props;
  //PROPS

  //STATES
  const [showMes, setShowMes] = useState(false);
  //STATES

  //FUNCS
  function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      setShowMes(true);
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }

    document.body.removeChild(textArea);
  }

  function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(
      function () {
        setShowMes(true);
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  }

  const copyFunction = () => {
    copyTextToClipboard(uri);
  };
  //FUNCS

  return (
    <Wrapper className="container container--small">
      <p className={showMes ? "copyMes copyMes--show" : "copyMes"}>
        Copied to Clipboard √
      </p>
      <button
        onClick={copyFunction}
        aria-label="Share Page"
        className={showMes ? "copyBut copyBut--show" : "copyBut"}
      >
        <ShareButton />
      </button>
    </Wrapper>
  );
};

export default Share;
