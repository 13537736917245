import React from "react";

// import flexible content modules
import Images from "./images";
import Video from "./video";
import TextBlock from "./textBlock";

const WorkContent = (props) => {
  //PROPS
  const { rows, data, dimensions, videoID, setVideoID } = props;
  //PROPS

  if (!!rows) {
    return rows
      .filter((o) => o !== null)
      .map(({ __typename, ...rowData }, index) => {
        const type = __typename.split("_").slice(-1)[0];

        const components = {
          Images,
          TextBlock,
          Video
        };
        const Component = components[type];
        return (
          Component && (
            <Component
              videoID={videoID}
              setVideoID={setVideoID}
              key={index}
              {...rowData}
              {...data}
              dimensions={dimensions}
            />
          )
        );
      });
  } else {
    return null;
  }
};

export default WorkContent;
