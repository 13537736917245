import React, { useEffect, useState } from "react";

import useNativeLazyLoading from "@charlietango/use-native-lazy-loading";
import { useInView } from "react-intersection-observer";
import StackGrid from "react-stack-grid";
import Img from "gatsby-image";

import { Wrapper, ImageContainer } from "./Images.styles";
import Lightbox from "components/ligthbox";

const Image = (props) => {
  //PROPS
  const { images, setLightBox, image, index, alt, height, width } = props;
  //PROPS

  //INVIEW
  const supportsLazyLoading = useNativeLazyLoading();

  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: "200px 0px"
  });
  //INVIEW

  return (
    <ImageContainer
      height={height}
      width={width}
      ref={ref}
      onClick={() =>
        setLightBox({
          images: images,
          showLightbox: true,
          show: index
        })
      }
    >
      {image && inView ? <Img fluid={image} alt={alt} /> : null}
    </ImageContainer>
  );
};

const Images = (props) => {
  //PROPS
  const { images, dimensions } = props;
  //PROPS

  //STATES
  const [lightBox, setLightBox] = useState({
    showLightbox: false,
    images: null
  });
  const [width, setWidth] = useState(3);
  //STATES

  //EFFECTS
  useEffect(() => {
    let isMobile = dimensions.width <= 600;
    let isTablet = dimensions.width <= 969 && dimensions.width > 600;
    let isDesk = dimensions.width > 969;

    if (isMobile) {
      setWidth("100%");
    } else if (isTablet && images.length >= 2) {
      setWidth("50%");
    } else if (isTablet && images.length === 1) {
      setWidth("100%");
    } else if (isDesk && images.length === 1) {
      setWidth("100%");
    } else if (isDesk && images.length === 2) {
      setWidth("50%");
    } else {
      setWidth("33.333333%");
    }
  }, [images, dimensions]);
  //EFFECTS

  return (
    <Wrapper>
      {images.length >= 2 ? (
        <StackGrid columnWidth={width} monitorImagesLoaded={true} duration={0}>
          {images.map((item, index) => {
            let image = item.image?.localFile?.childImageSharp.fluid;

            let arr = images.map((e) => e.image);
            arr.splice(index, images.length - index);

            let arr2 = images.map((e) => e.image);
            arr2.splice(0, index);

            let arr3 = [].concat(arr2, arr);

            return (
              <Image
                key={index}
                images={arr3}
                setLightBox={setLightBox}
                image={image}
                width={item.image?.mediaDetails?.width}
                height={item.image?.mediaDetails?.height}
                index={index}
                alt={item.image?.altText}
              />
            );
          })}
        </StackGrid>
      ) : (
        <div className="singleImageWrapper">
          <Image
            images={images}
            setLightBox={setLightBox}
            image={images[0].image?.localFile?.childImageSharp.fluid}
            width={images[0].image?.mediaDetails?.width}
            height={images[0].image?.mediaDetails?.height}
            alt={images[0].image?.altText}
          />
        </div>
      )}
      <Lightbox lightBox={lightBox} setLightBox={setLightBox} />
    </Wrapper>
  );
};

export default Images;
