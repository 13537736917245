import React, { useEffect, useRef } from "react";

import Slider from "react-slick";
import Img from "gatsby-image";

import gsap from "gsap";

import {
  LightboxContainer,
  ImageContainer,
  Close,
  Slide,
  Slideshow
} from "./Lightbox.styles";

const Lightbox = (props) => {
  //PROPS
  const { lightBox, setLightBox } = props;
  //PROPS

  //GSAP
  const tl = gsap.timeline();
  const introAnim = () => {
    tl.to(".slider-car", {
      duration: 0.01,
      css: {
        zIndex: "201"
      }
    })
      .to(".slider-car", {
        duration: 0.4,
        css: {
          opacity: 1
        }
      })
      .to("body", {
        overflow: "hidden"
      });
  };

  const closeAnim = () => {
    tl.to(".slider-car", {
      duration: 0.4,
      css: {
        opacity: 0
      }
    })
      .to(".slider-car", {
        duration: 0.01,
        css: {
          zIndex: "-1"
        }
      })
      .to("body", {
        overflow: "auto"
      });
  };
  //GSAP

  //DEFS
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true
  };
  //DEFS

  //REFS
  const slider = useRef(null);
  //REFS

  //EFFECTS
  useEffect(() => {
    if (lightBox.showLightbox) {
      introAnim();
      document.querySelector("html").classList.add("noover");
    } else {
      document.querySelector("html").classList.remove("noover");
      closeAnim();
    }
  }, [lightBox]);
  //EFFECTS

  return (
    <LightboxContainer className="slider-car">
      <Close onClick={() => setLightBox({ ...lightBox, showLightbox: false })}>
        <img src="/images/close.svg" alt="close" />
      </Close>
      <Slideshow>
        <Slider
          {...settings}
          style={{ height: "100vh" }}
          ref={slider}
          key={lightBox.showLightbox}
        >
          {lightBox.images &&
            lightBox.images.map((image, index) => {
              return (
                <Slide key={index}>
                  <ImageContainer key={index}>
                    <Img
                      alt={image.altText ? image.altText : "Image description"}
                      fluid={image.localFile?.childImageSharp.fluid}
                    />
                  </ImageContainer>
                </Slide>
              );
            })}
        </Slider>
      </Slideshow>
    </LightboxContainer>
  );
};

export default Lightbox;
