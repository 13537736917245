import styled from "styled-components";

import { toRem } from "utils/mixins";

export const Wrapper = styled.div`
  padding-bottom: ${toRem(40)};
  padding-top: ${toRem(40)};
  position: relative;
  .copyMes {
    left: 50%;
    transform: translateX(-50%) translateY(5px);
    font-weight: 500;
    opacity: 0;
    text-align: center;
    position: absolute;
    transition: 400ms;
    &--show {
      transform: translateX(-50%) translateY(0);
      opacity: 1;
    }
  }
  .copyBut {
    left: 50%;
    transform: translateX(-50%) translateY(0);
    position: absolute;
    opacity: 1;
    transition: 400ms;
    &--show {
      transform: translateX(-50%) translateY(-5px);
      opacity: 0;
    }
  }
  input {
    visibility: hidden;
    opacity: 0;
    position: absolute;
  }
  svg {
    width: ${toRem(20)};
    height: ${toRem(20)};
  }
`;
