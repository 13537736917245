import styled from "styled-components";

import { white } from "theme/colors";

export const Wrapper = styled.div`
  width: 100%;
  height: auto;
  .singleImageWrapper {
    max-height: 60vh;
    overflow: hidden;
    .gatsby-image-wrapper {
      max-height: 60vh;
      img {
        object-fit: contain !important;
      }
    }
  }
`;

export const ImageContainer = styled.div`
  cursor: pointer;
  position: relative;
  padding-bottom: ${(props) => (props.height / props.width) * 100 + "%"};
  background: ${white.default};
  line-height: 0;
  transition: 0.25s ease-in-out opacity;

  .gatsby-image-wrapper {
    left: 0;
    top: 0;
    position: absolute !important;
    width: 100%;
    height: 100%;
    line-height: 0;

    img {
      line-height: 0;
      object-fit: cover !important;
      padding: 8px 0;
      @media (min-width: 769px) {
        padding: 8px;
      }
    }
  }

  &:hover {
    opacity: 0.8;
  }
`;
