import styled from "styled-components";

import { black } from "theme/colors";
import { toRem } from "utils/mixins";

export const LightContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const LightboxContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: ${black.default};
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
`;

export const Slideshow = styled.div`
  width: 100%;
  * {
    outline: none;
    height: auto;
  }
  .slick-prev {
    left: ${toRem(15)};
    transform: rotate(180deg);
  }

  .slick-next {
    right: ${toRem(15)};
  }

  .slick-arrow {
    content: "";
    z-index: 2;
    height: ${toRem(30)};
    width: ${toRem(30)};
    position: absolute;
    transition: 400ms;
    font-size: 0;
    cursor: pointer;
    background-color: transparent;
    border: none;
    background-repeat: no-repeat;
    background-position: center;
    background-size: ${toRem(30)};
    bottom: ${toRem(25)};
    background-image: url("/images/arrow.svg");
    &::before {
      display: none;
    }
    &:hover {
      opacity: 0.2;
    }
    @media (min-width: 801px) {
      top: 50%;
      bottom: unset;
    }
  }
`;

export const Slide = styled.div`
  height: calc(100vh - 80px);
  width: 100vw;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 801px) {
    height: 100vh;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  height: calc(100vh - 80px);
  width: 100vw;
  align-items: center;
  justify-content: center;

  .gatsby-image-wrapper {
    z-index: 2;
    display: block;
    padding: 0;
    width: 100vw;
    height: 100vh;
    object-position: center center;
    transition: 0.5s ease-in-out opacity;
    img {
      object-fit: scale-down !important;
    }
  }

  @media (min-width: 801px) {
    height: 100vh;

    .gatsby-image-wrapper {
      img {
        padding: ${toRem(50)};
      }
    }
  }
`;

export const Close = styled.div`
  bottom: ${toRem(25)};
  right: 50%;
  transform: translateX(50%);
  position: absolute;
  z-index: 5;
  @media (min-width: 801px) {
    top: ${toRem(25)};
    bottom: unset;
    right: ${toRem(25)};
    transform: translateX(0);
  }
  &:hover {
    opacity: 0.2;
  }
  img {
    width: 100%;
    object-fit: contain;
  }
`;
