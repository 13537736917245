import React from "react";

import VideoPlayer from "components/video";
import { Wrapper } from "./Video.styles";
import { useInView } from "react-intersection-observer";

const Video = (props) => {
  //PROPS
  const { previewVideo, vimeo, videoID, setVideoID } = props;
  //PROPS

  //INVIEW
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 1
  });
  //INVIEW

  return (
    <Wrapper ref={ref}>
      <VideoPlayer
        id={vimeo}
        videoID={videoID}
        isLoad={true}
        isIn={inView}
        vimeoPlay={true}
        setVideoID={setVideoID}
        preview={previewVideo?.mediaItemUrl}
      />
    </Wrapper>
  );
};

export default Video;
