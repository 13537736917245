import styled from "styled-components";
import { toRem } from "utils/mixins";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  position: relative;
  margin-bottom: ${toRem(30)};
  &:hover {
    cursor: pointer;
  }
`;
