import React, { useState } from "react";
import { graphql } from "gatsby";

import WorkContent from "components/workContent";
import TextBlock from "components/workContent/textBlock";
import Share from "components/workContent/share";

const NewPage = (props) => {
  //PROPS
  const {
    dimensions,
    location,
    data: {
      page: {
        title,
        news_info: { content, client, longDescription }
      }
    }
  } = props;
  //PROPS

  //STATES
  const [videoID, setVideoID] = useState(null);
  //STATES

  //console.log(location);

  return (
    <>
      <main className="main main--pad container container--small container--center">
        <WorkContent
          rows={content}
          dimensions={dimensions}
          videoID={videoID}
          setVideoID={setVideoID}
        />
        <div className="project-info">
          <h4>{client}</h4>
          <h1 className="h1-underline">{title}</h1>
          {!!longDescription && <TextBlock text={longDescription} />}
          <Share uri={location.href}></Share>
        </div>
      </main>
    </>
  );
};

export const query = graphql`
  query newPage($id: String) {
    page: wpNew(id: { eq: $id }) {
      title
      id
      uri
      seo {
        seoDescription
        seoTitle
        seoImage {
          localFile {
            url
          }
        }
      }
      news_info {
        client
        longDescription
        content {
          ... on WpNew_NewsInfo_Content_Images {
            images {
              image {
                altText
                mediaDetails {
                  height
                  width
                }
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 3600, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          ... on WpNew_NewsInfo_Content_Video {
            vimeo
            previewVideo {
              mediaItemUrl
            }
          }
          ... on WpNew_NewsInfo_Content_TextBlock {
            text
            title
          }
        }
      }
    }
    allWpSkill {
      edges {
        node {
          slug
          name
          id
          language {
            code
          }
        }
      }
    }
  }
`;

export default NewPage;
